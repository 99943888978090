// @flow
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import QueryFilterItemUtil from 'models/core/wip/QueryFilterItem/QueryFilterItemUtil';
import type DimensionValueFilterItem from 'models/core/wip/QueryFilterItem/DimensionValueFilterItem';

const DruidCaseUtil = {
  /**
   * Given a map of primary dimension values, specified either as an object or
   * a ZenMap, return a promise with the array of DimensionValueFilterItems
   * to filter any query down to the specific values we need.
   */
  getDimensionFilterItems(
    primaryDimensionValues:
      | Zen.Map<string>
      | { +[dimensionName: string]: string },
  ): Promise<Array<DimensionValueFilterItem>> {
    const map =
      primaryDimensionValues instanceof Zen.Map
        ? primaryDimensionValues
        : Zen.Map.create(primaryDimensionValues);

    const dimensionFilterPromises = map
      .entries()
      .map(([dimensionId, dimensionValue]) =>
        QueryFilterItemUtil.getDimensionFilterItemFromSearchTerm(
          dimensionValue,
          dimensionId,
        ),
      );

    return Promise.all(
      dimensionFilterPromises,
    ).then((filterItems: Array<DimensionValueFilterItem | void>) =>
      filterItems.filter(Boolean),
    );
  },
};

export default DruidCaseUtil;
