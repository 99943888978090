// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AskAI(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="24"
      width="24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.704 10.828c0 .066-.047.12-.11.133-5.797 1.111-6.486 2.005-7.36 9.553a.137.137 0 0 1-.137.119.137.137 0 0 1-.137-.12c-.859-7.549-1.542-8.444-7.34-9.567a.137.137 0 0 1-.11-.133c0-.066.047-.12.11-.133 5.798-1.112 6.486-2.005 7.36-9.553a.137.137 0 0 1 .137-.12c.072 0 .13.053.138.12.858 7.55 1.542 8.445 7.34 9.568.062.014.109.068.109.133ZM21.495 17.795a.067.067 0 0 1-.055.064c-2.898.538-3.242.97-3.68 4.622a.068.068 0 0 1-.068.058.068.068 0 0 1-.069-.058c-.429-3.653-.77-4.086-3.67-4.63a.067.067 0 0 1-.055-.064c0-.031.024-.058.056-.064 2.898-.538 3.242-.97 3.679-4.622a.068.068 0 0 1 .069-.058c.035 0 .064.025.068.058.43 3.653.771 4.086 3.67 4.63a.067.067 0 0 1 .055.064Z"
        fill="currentColor"
      />
    </svg>
  );
}
